

















import { Component, Vue } from "vue-property-decorator";
import AlertModule from "@/store/modules/alert";

/**
 * Alert component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class Alert extends Vue {
    get alerts() {
        return AlertModule.alerts;
    }
}
