export enum AlertType {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
}

export interface AlertProperties {
    type: AlertType;
    message: string;
}

export interface Showable {
    show: boolean;
}
