




















































































































import { Component, Vue } from "vue-property-decorator";
import LanguageSwitcher from "@/components/i18n/LanguageSwitcher.vue";
import ArticleSearchBar from "@/components/shop/article/search/ArticleSearchBar.vue";
import AuthModule from "@/store/modules/auth";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { abilities } from "@/plugins/casl/abilities";
import * as navbarHelper from "@/utils/navbarhelper";
import { MenuButton } from "@/utils/navbarhelper/types";
import { User } from "@/types/user";

/**
 * Navbar component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        LanguageSwitcher,
        ArticleSearchBar,
    },
})
export default class Navbar extends Vue {
    private filteredMenuButtons: MenuButton[] = [];

    private availableLanguages: string[] = [];

    /**
     * @return User | null
     */
    get user(): User | null {
        // TODO remove ts-ignore when vuex generics fixed
        // @ts-ignore
        return AuthModule.user;
    }

    /**
     * @return user abbreviation (string)
     */
    get userAbbreviation(): string {
        if (this.user === null) return "";

        return `${this.user.firstname[0].toUpperCase()} ${this.user.lastname[0].toUpperCase()}`;
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.filterMenuButtons();
        abilities.on("updated", () => {
            this.filterMenuButtons();
        });

        try {
            this.availableLanguages = await this.fetchLanguages();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all languages
     *
     * @returns Promise<string[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchLanguages(): Promise<string[]> {
        const response = (await axios.get<APIResponse<string[]>>("/languages"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Saves the routes in filteredMenuButtons where the user has access to
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private filterMenuButtons() {
        const menuButtons: MenuButton[] = [
            {
                to: { name: "shop" },
                exact: false,
                text: "app.navbar.shop",
            },
            {
                to: { name: "adminPanel" },
                exact: false,
                text: "app.navbar.adminPanel",
            },
        ];

        this.filteredMenuButtons = navbarHelper.filterMenuButtons(menuButtons);
    }

    /**
     * Logs the user out
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private logout() {
        AuthModule.logout();
    }
}
