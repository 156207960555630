




import { Component, Vue } from "vue-property-decorator";

/**
 * EmptyRouterView view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class EmptyRouterView extends Vue {}
