import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import "./plugins/casl/vue";
import AuthModule from "./store/modules/auth";

Vue.config.productionTip = false;

// Try login with token form localStorage
AuthModule.tryAutoLogin().finally(() => {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");
});
