























































import { Component, Watch, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/auth";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { ArticleCategory } from "@/types/shop/article";

@Component
export default class Menubar extends Vue {
    private articleCategories: ArticleCategory[] = [];

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.fetchData();
        this.$root.$on(
            "articleCategoriesUpdated",
            (articleCategories: ArticleCategory[]) =>
                (this.articleCategories = articleCategories)
        );
    }

    /**
     * Function will be automatic called by Vue.js when locale was changed
     * Function calls fetchData
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Watch("$root.$i18n.locale")
    private onLocaleChanged() {
        this.fetchData();
    }

    /**
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchData() {
        try {
            this.articleCategories = await this.fetchArticleCategories();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all article categories
     *
     * @returns Promise<ArticleCategory[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleCategories(): Promise<ArticleCategory[]> {
        const response = (
            await axios.get<APIResponse<ArticleCategory[]>>(
                "/articles/categories"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    private get isAuthenticated(): boolean {
        return AuthModule.isAuthenticated;
    }
}
