






































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Topbar extends Vue {
    private contactInformations = [
        {
            href: "tel:+49 (0) 2375 203903",
            icon: "mdi-phone",
            text: "+49 (0) 2375 203903",
        },
        {
            href: "mailto:info@skiba-procomputer.de",
            icon: "mdi-email",
            text: "info@skiba-procomputer.de",
        },
    ];

    private socialMediaList = [
        {
            href: "https://www.instagram.com/skiba.pro",
            icon: "mdi-instagram",
        },
        {
            href: "https://www.facebook.com/skiba.procomputer",
            icon: "mdi-facebook",
        },
        {
            href: "https://www.linkedin.com/company/skiba-procomputertechnik-gmbh",
            icon: "mdi-linkedin",
        },
    ];
}
