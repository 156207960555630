import {
    VuexModule,
    Module,
    getModule,
    config,
    Mutation,
} from "vuex-module-decorators";
import store from "@/store/";
import { AlertProperties, Showable } from "./types/";

// Set rawError to true for all @Action
config.rawError = true;

/**
 * VuexModule for alert management
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Module({ store: store, namespaced: true, name: "alert", dynamic: true })
class AlertModule<AlertPropertyType = AlertProperties> extends VuexModule {
    // Alert Array
    public alerts: (AlertPropertyType & Showable)[] = [];

    /**
     * Creates a new alert object with the given values and calls SET_ALERT
     *
     * @param alertProperties alertProperties
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    public showAlert(alertProperties: AlertPropertyType) {
        const alert: AlertPropertyType & Showable = {
            ...alertProperties,
            show: true,
        };

        this.alerts.push(alert);
    }
}

// Exports AlertModule
export default getModule(AlertModule);

// Export all types
export * from "./types";
