
























import { Component, Prop, Vue } from "vue-property-decorator";
import CountryFlag from "vue-country-flag";
import axios from "@/plugins/axios";

/**
 * LanguageSwitcher component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        CountryFlag,
    },
})
export default class LanguageSwitcher extends Vue {
    @Prop({ type: Array, required: true }) readonly languages!: string[];

    /**
     * @returns current locale
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get currentLocale() {
        return this.$root.$i18n.locale;
    }

    /**
     * Sets current locale
     *
     * @param langCode language code string
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private set currentLocale(langCode: string) {
        this.$root.$i18n.locale = langCode;
        this.$root.$vuetify.lang.current = langCode;
        axios.defaults.headers.common["Accept-Language"] = langCode;
        localStorage.setItem("language", langCode);
    }
}
