var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthenticated)?_c('nav',[_c('v-toolbar',{attrs:{"justify":"center","height":"40px","id":"menubar"}},[_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},_vm._l((_vm.articleCategories),function(articleCategory,i){return _c('v-btn',{key:articleCategory.id,class:{ 'ml-4': i > 0 },attrs:{"color":"white","text":"","to":{
                    name: 'shopCategoryDetails',
                    params: { slug: articleCategory.names[0].name },
                }}},[_vm._v(" "+_vm._s(articleCategory.names[0].name)+" ")])}),1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({staticClass:"hidden-md-and-up",attrs:{"dark":""}},on))]}}],null,false,4160917778)},[_c('v-list',_vm._l((_vm.articleCategories),function(articleCategory){return _c('v-list-item',{key:articleCategory.id},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":{
                                    name: 'shopCategoryDetails',
                                    params: {
                                        slug: articleCategory.names[0].name,
                                    },
                                }}},[_vm._v(" "+_vm._s(articleCategory.names[0].name)+" ")])],1)],1)],1)}),1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }