import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AuthModule from "@/store/modules/auth";
import { abilities } from "@/plugins/casl/abilities";
import EmptyRouterView from "@/views/router/EmptyRouterView.vue";

Vue.use(VueRouter);

// TODO create base admin view component with dynamic table component (see <component :is="" />)

const routes: Array<RouteConfig> = [
    {
        path: "/shop",
        name: "shop",
        component: EmptyRouterView,
        redirect: { name: "shopMainPage" },
        children: [
            {
                path: "",
                name: "shopMainPage",
                component: () => import("@/views/shop/ShopMainPage.vue"),
            },
            {
                path: "categories",
                component: EmptyRouterView,
                children: [
                    {
                        path: ":slug",
                        name: "shopCategoryDetails",
                        component: () =>
                            import(
                                "@/views/shop/article/category/ShopArticleCategoryDetails.vue"
                            ),
                    },
                ],
            },
            {
                path: "articles",
                component: EmptyRouterView,
                children: [
                    {
                        path: ":id",
                        name: "shopArticleDetails",
                        component: () =>
                            import(
                                "@/views/shop/article/ShopArticleDetails.vue"
                            ),
                    },
                ],
            },
            {
                path: "cart",
                name: "shoppingCart",
                component: () =>
                    import("@/views/shop/user/cart/UserShoppingCart.vue"),
            },
            {
                path: "orders",
                name: "shopOrders",
                component: () =>
                    import("@/views/shop/user/order/ShopUserOrderDetails.vue"),
            },
        ],
        meta: {
            requiresLogin: true,
        },
    },
    {
        path: "/admin",
        name: "adminPanel",
        component: () => import("@/views/admin/AdminPanel.vue"),
        meta: {
            requiresLogin: true,
            requiredPermission: {
                action: "read",
                subject: "AdminPanel",
            },
        },

        redirect: { name: "AdminAnalyticsManagement" },
        children: [
            {
                path: "properties",
                name: "AdminPanelPropertyManagement",
                component: () =>
                    import(
                        "@/views/admin/property/AdminPropertyManagement.vue"
                    ),
                meta: {
                    requiredPermission: {
                        action: "read",
                        subject: "ArticleProperty",
                    },
                },
            },
            {
                path: "users",
                component: EmptyRouterView,
                children: [
                    {
                        path: "",
                        name: "AdminPanelUserManagement",
                        component: () =>
                            import(
                                "@/views/admin/user/AdminUserManagement.vue"
                            ),
                        meta: {
                            requiredPermission: {
                                action: "read",
                                subject: "User",
                            },
                        },
                    },
                    {
                        path: "analytics",
                        name: "AdminAnalyticsManagement",
                        component: () =>
                            import(
                                "@/views/admin/user/analytics/AdminAnalyticsManagement.vue"
                            ),
                        meta: {
                            requiredPermission: {
                                action: "read",
                                subject: "Analytics",
                            },
                        },
                    },
                    {
                        path: "companies",
                        name: "AdminPanelUserCompanyManagement",
                        component: () =>
                            import(
                                "@/views/admin/user/company/AdminUserCompanyManagement.vue"
                            ),
                        meta: {
                            requiredPermission: {
                                action: "read",
                                subject: "Company",
                            },
                        },
                    },
                    {
                        path: "permissions",
                        component: EmptyRouterView,
                        children: [
                            {
                                path: "groups",
                                name: "AdminPanelUserPermissionGroupManagement",
                                component: () =>
                                    import(
                                        "@/views/admin/user/permission/group/AdminUserPermissionGroupManagement.vue"
                                    ),
                                meta: {
                                    requiredPermission: {
                                        action: "read",
                                        subject: "PermissionGroup",
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "shop",
                name: "AdminPanelShop",
                component: EmptyRouterView,
                children: [
                    {
                        path: "articles",
                        component: EmptyRouterView,
                        meta: {
                            requiredPermission: {
                                action: "read",
                                subject: "Article",
                            },
                        },
                        children: [
                            {
                                path: "",
                                name: "AdminPanelShopArticleManagement",
                                component: () =>
                                    import(
                                        "@/views/admin/shop/article/AdminShopArticleManagement.vue"
                                    ),
                            },
                            {
                                path: "categories",
                                name: "AdminPanelShopArticleCategoryManagement",
                                component: () =>
                                    import(
                                        "@/views/admin/shop/article/category/AdminShopArticleCategoryManagement.vue"
                                    ),
                                meta: {
                                    requiredPermission: {
                                        action: "read",
                                        subject: "ArticleCategory",
                                    },
                                },
                            },
                            {
                                path: "groups",
                                name: "AdminPanelShopArticleGroupManagement",
                                component: () =>
                                    import(
                                        "@/views/admin/shop/article/group/AdminShopArticleGroupManagement.vue"
                                    ),
                                meta: {
                                    requiredPermission: {
                                        action: "read",
                                        subject: "ArticleGroup",
                                    },
                                },
                            },
                            {
                                path: "producers",
                                name: "AdminPanelShopArticleProducerManagement",
                                component: () =>
                                    import(
                                        "@/views/admin/shop/article/producer/AdminShopArticleProducerManagement.vue"
                                    ),
                                meta: {
                                    requiredPermission: {
                                        action: "read",
                                        subject: "ArticleProducer",
                                    },
                                },
                            },
                            {
                                path: "price/classes",
                                name: "AdminPanelShopArticlePriceClassManagement",
                                component: () =>
                                    import(
                                        "@/views/admin/shop/article/price/class/AdminShopArticlePriceClassManagement.vue"
                                    ),
                                meta: {
                                    requiredPermission: {
                                        action: "read",
                                        subject: "ArticlePriceClass",
                                    },
                                },
                            },
                        ],
                    },
                    {
                        path: "deals",
                        name: "AdminPanelShopDealManagement",
                        component: () =>
                            import(
                                "@/views/admin/shop/deal/AdminShopDealManagement.vue"
                            ),
                        meta: {
                            requiredPermission: {
                                action: "read",
                                subject: "ArticleDeal",
                            },
                        },
                    },
                    {
                        path: "orders",
                        name: "AdminPanelShopOrderManagement",
                        component: () =>
                            import(
                                "@/views/admin/shop/order/AdminShopOrderManagement.vue"
                            ),
                        meta: {
                            requiredPermission: {
                                action: "read",
                                subject: "Order",
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/profile",
        name: "userProfile",
        component: () => import("@/views/user/UserProfile.vue"),
    },
    {
        path: "/company",
        name: "userCompanySettings",
        component: () => import("@/views/user/company/UserCompanySettings.vue"),
        meta: {
            requiredPermission: {
                action: "update",
                subject: "OwnCompany",
            },
        },
    },
    {
        path: "/privacy-policy",
        name: "privacyPolicy",
        component: () => import("@/views/PrivacyPolicy.vue"),
    },
    {
        path: "/imprint",
        name: "imprint",
        component: () => import("@/views/Imprint.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/account/Login.vue"),
    },
    {
        path: "*",
        redirect: { name: "shop" },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (
        to.matched.some(
            (record) => record.meta.requiresLogin && !AuthModule.isAuthenticated
        )
    ) {
        next({ name: "login", query: { nextUrl: to.fullPath } });
    } else if (
        to.matched.some(
            (record) =>
                record.meta.requiredPermission &&
                record.meta.requiredPermission.action &&
                record.meta.requiredPermission.subject &&
                !abilities.can(
                    record.meta.requiredPermission.action,
                    record.meta.requiredPermission.subject
                )
        )
    ) {
        next(false);
    } else {
        next();
    }
});

export default router;
