


























































import { Component, Vue } from "vue-property-decorator";
import axios, { APIResponse } from "@/plugins/axios";
import { ArticleSearchResult } from "@/types/shop/article";

/**
 * ArticleSearchBar component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class ArticleSearchBar extends Vue {
    private articleSearchResults: ArticleSearchResult = {};
    private expansionPanelValue: number[] = [];
    private showMenu = false;

    /**
     * Search articles by search string and overwrite the articleSearchResult object with the result
     *
     * @param searchString search string
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async searchArticles(searchString: string) {
        if (searchString === "") return;

        const response = (
            await axios.get<APIResponse<ArticleSearchResult>>(
                "/article/search/" + searchString
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        this.articleSearchResults = Object.fromEntries(
            Object.entries(response.data).filter(
                ([_, v]) => Array.isArray(v) && v.length > 0
            )
        );
        const expansionPanelValue = [];
        for (
            let i = 0;
            i < Object.keys(this.articleSearchResults).length;
            i++
        ) {
            expansionPanelValue.push(i);
        }
        this.expansionPanelValue = expansionPanelValue;
    }

    /**
     * redirect to article details page
     *
     * @param articleId article id
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private redirectToArticleDetails(articleId: number) {
        this.showMenu = false;
        this.$router.push({
            name: "shopArticleDetails",
            params: {
                id: articleId.toString(),
            },
        });
    }
}
