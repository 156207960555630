

















import { Component, Vue } from "vue-property-decorator";
import AppNavbar from "@/components/Navbar.vue";
import AppAlert from "@/components/Alert.vue";
import AppTopbar from "@/components/Topbar.vue";
import AppFooter from "@/components/Footer.vue";
import AppMenubar from "@/components/Menubar.vue";

/**
 * App view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AppNavbar,
        AppAlert,
        AppTopbar,
        AppFooter,
        AppMenubar,
    },
})
export default class App extends Vue {}
